import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import type { AppProps } from 'next/app';
import { Flip, ToastContainer } from 'react-toastify';
import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { RecoilRoot } from 'recoil';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { SWUpdater } from '../src/components/pwa/SWUpdater';

const MyApp = ({ Component, pageProps }: AppProps): unknown => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                cacheTime: Infinity, // 24 hours
                staleTime: 10000,
                retry: 3,
            },
            mutations: {
                retry: 1,
            },
        },
    });

    // Docs: https://tanstack.com/query/v4/docs/react/plugins/createAsyncStoragePersister
    const asyncStoragePersister = createAsyncStoragePersister({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        storage: AsyncStorage,
    });

    return (
        <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister: asyncStoragePersister }}
        >
            <RecoilRoot>
                <Component {...pageProps} />
                <ToastContainer
                    position="top-center"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    transition={Flip}
                />
            </RecoilRoot>
            <SWUpdater />
            <ReactQueryDevtools />
        </PersistQueryClientProvider>
    );
};

export default MyApp;
